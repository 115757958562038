import React from 'react';
import { FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = {
    position: 'absolute', 
    right: 10, 
    bottom: 9, 
    color: '#1db954'
};

class FormGroupLoading extends React.Component {
    render() {
        return (
            <FormGroup>
                {this.props.children}
                {
                    this.props.loading ? 
                        <FontAwesomeIcon icon="circle-notch" spin size="lg" style={style} />
                    : ''
                }
            </FormGroup>
        );
    }
}

export default FormGroupLoading;