import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCidades } from '../../ducks/app';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import InputMask from 'react-input-mask';
import FormGroupLoading from '../form/formGroupLoading';

class EnderecoForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			cidades: null,
			disabled: false
		};

		this.consultaCEP = this.consultaCEP.bind(this);
		this.consultaCidades = this.consultaCidades.bind(this);
		if (this.state.disabled) {
			this.consultaCEP = null;
			this.consultaCidades = null;
		}
	}

	componentDidMount() {
		if (this.props.cidadesByUF.length === 0) {
			this.props.getCidades(() => this.consultaCidades(this.props.endereco.estado));
		} else {
			this.consultaCidades(this.props.endereco.estado);
		}
		
	}

	consultaCidades(event) {
		const uf = event.target ? event.target.value : event;
		if (uf.length === 2) {
			this.setState({cidades: this.props.cidadesByUF.find(cidade => cidade.uf === uf)});
		}
	}

	consultaCEP(event) {
		const cep = event.target.value;
		if (cep.length === 8) {

			this.setState({disabled: true});

			axios.get(`http://viacep.com.br/ws/${cep}/json/`, {timeout: 5000})
				.then(response => {
					this.props.change({target: document.querySelector('#logradouro')}, response.data.logradouro);
					this.props.change({target: document.querySelector('#bairro')}, response.data.bairro);
					this.props.change({target: document.querySelector('#estado')}, response.data.uf);
					this.consultaCidades({target: document.querySelector('#estado')});
					this.props.change({target: document.querySelector('#cidade')}, response.data.ibge);
					this.setState({disabled: false});
				})
				.catch(err => {
					this.setState({disabled: false});
				});
		}
	}

	render() {
		return (
			<fieldset>
				<legend>Endereço</legend>
				{/*<FormGroup>
					<Label for="nome">Nome</Label>
					<Input type="text" name="endereco.nome" id="nome" value={this.props.endereco.nome} required onChange={this.props.change} />
					<small className="form-text text-muted">Campo identificador do endereço. Ex: Casa, Trabalho.</small>
				</FormGroup>*/}
				<Row>
					<Col sm="3">
						<FormGroupLoading loading={this.state.disabled}>
							<Label for="cep">Cep</Label>
							<InputMask mask="99999999" maskChar="" name="endereco.cep" id="cep" className="form-control" value={this.props.endereco.cep} required onChange={this.props.change} onBlur={this.consultaCEP} disabled={this.props.disabled || this.state.disabled} />
						</FormGroupLoading>
					</Col>
					<Col sm="7">
						<FormGroup>
							<Label for="logradouro">Logradouro</Label>
							<Input type="text" name="endereco.logradouro" id="logradouro" value={this.props.endereco.logradouro} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="numero">Número</Label>
							<Input type="text" name="endereco.numero" id="numero" value={this.props.endereco.numero} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col sm="5">
						<FormGroup>
							<Label for="bairro">Bairro</Label>
							<Input type="text" name="endereco.bairro" id="bairro" value={this.props.endereco.bairro} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="estado">Estado</Label>
							<Input type="select" name="endereco.estado" id="estado" value={this.props.endereco.estado} required onChange={(event) => {this.props.change(event); this.consultaCidades(event)}} disabled={this.props.disabled || this.state.disabled}>
								<option value=""></option>
								<option value="AC">AC</option>
								<option value="AL">AL</option>
								<option value="AM">AM</option>
								<option value="AP">AP</option>
								<option value="BA">BA</option>
								<option value="CE">CE</option>
								<option value="DF">DF</option>
								<option value="ES">ES</option>
								<option value="GO">GO</option>
								<option value="MA">MA</option>
								<option value="MG">MG</option>
								<option value="MS">MS</option>
								<option value="MT">MT</option>
								<option value="PA">PA</option>
								<option value="PB">PB</option>
								<option value="PE">PE</option>
								<option value="PI">PI</option>
								<option value="PR">PR</option>
								<option value="RJ">RJ</option>
								<option value="RN">RN</option>
								<option value="RO">RO</option>
								<option value="RR">RR</option>
								<option value="RS">RS</option>
								<option value="SC">SC</option>
								<option value="SE">SE</option>
							</Input>
						</FormGroup>
					</Col>
					<Col sm="5">
						<FormGroup>
							<Label for="cidade">Cidade</Label>
							<Input type="select" name="endereco.id_cidade" id="cidade" value={this.props.endereco.id_cidade} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled}>
								<option value=""></option>
								{
									this.state.cidades && this.state.cidades.nomes.map( (cidade, index) =>
										<option key={`cidade-${this.state.cidades.codigos[index]}`} value={this.state.cidades.codigos[index]}>{cidade}</option>
									)
								}
							</Input>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="complemento">Complemento</Label>
					<Input type="text" name="endereco.complemento" id="complemento" value={this.props.endereco.complemento} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
				</FormGroup>
			</fieldset>
		);
	}
}

const mapStateToProps = (state) => ({
	cidadesByUF: state.app.cidadesByUF
});

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		getCidades
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(EnderecoForm)

export const enderecoFormSchema = {
	nome: '',
	logradouro: '',
	numero: '',
	bairro: '',
	complemento: '',
	cep: '',
	lat: '',
	lng: '',
	estados: '',
	estado: '',
	id_cidade: '',
	disabled: false
};