import React from 'react';
import Page, { PublicPage } from '../layout/page';

import UsuarioList from './usuarioList';
import UsuarioForm from './usuarioForm';
import UsuarioFormEdit from './usuarioFormEdit';
import UsuarioSingle from './usuarioSingle';
import UsuarioPerfil from './usuarioPerfil';
import RecuperaSenhaForm from './recuperarSenhaForm';
import EsqueciSenhaForm from './esqueciSenhaForm';

const icon = 'users';
const title = 'Usuários';

export const UsuarioIndex = (props) => (
	<Page title={title} icon={icon}>
		<UsuarioList />
	</Page>
);

export const UsuarioAdd = (props) => (
	<Page title={title} icon={icon}>
		<UsuarioForm />
	</Page>
);

export const UsuarioEdit = ({props, match}) => (
	<Page title={title} icon={icon}>
		<UsuarioFormEdit match={match} />
	</Page>
);

export const UsuarioView = ({ props, match }) => ( 
	<Page title={title} icon={icon}>
		<UsuarioSingle match={match} />
	</Page>
);

export const UsuarioProfile = (props) => (
	<Page title={title} icon={icon}>
		<UsuarioPerfil />
	</Page>	
);

export const RecuperaSenha = (props) => (
	<PublicPage title="Recuperar Senha" icon="key">
		<RecuperaSenhaForm />
	</PublicPage>
);

export const EsqueciMinhaSenha = (props) => (
	<PublicPage title="Esqueci Minha Senha" icon="key">
		<EsqueciSenhaForm />
	</PublicPage>
);