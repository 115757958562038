const INITIAL_STATE = {
	titulo: '',
	tipo: 'default',
	visivel: false,
	conteudo: ''
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SHOW_MODAL_ALERT':
			return {...state, ...action.payload, visivel: true};
		case 'CLEAR_MODAL_ALERT':
			return INITIAL_STATE;
		default:
			return state;
	}
};