const INITIAL_STATE = {
	clientesById: [],
	parceirosById: [],
	agendamentosById: [],
	caixas: [],
	representantes: [],
	financeiroById: [],
	funcionarios: [],
	procedimentos: [],
	usuarios: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'STATE_CHANGE':
			return {...state, ...action.payload};
		case 'STATE_CLEAR':
			return {...state, [action.payload]: INITIAL_STATE[action.payload]};
		default:
			return state;
	}
};

export const changeState = (data) => {
	return { type: 'STATE_CHANGE', payload: data };
};

export const clearState = (objName) => {
	return { type: 'STATE_CLEAR', payload: objName };
};