import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { FormGroup, Label, Input, } from 'reactstrap';

const autenticaUsuario = (email, senha, redirect) => {
	const usuario = { email, senha };

	const request = axios.post('https://app.consultafacilthe.com.br/api/login', usuario);

	return dispatch => {
		request.then(response => {
			if (response.data.status === false) {
				dispatch({ type: 'APP_CHANGE', payload: {alerta: response.data.message} })
			} else {
				localStorage.setItem('token', response.data.key);
				localStorage.setItem('uid', response.data.uid);
				localStorage.setItem('email', response.data.email);
				dispatch({ type: 'PERFIL_GET', payload: {nome: response.data.nome, email: response.data.email, ulvl: response.data.level} });
				window.location.href = window.location.pathname;
			}
		})
	}
}

class loginForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			senha: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		const { name, value } = event.target;

		this.setState({[name]: value});
	}

	handleSubmit(event) {
		event.preventDefault();

		this.props.autenticaUsuario(this.state.email, this.state.senha);
	}

	render() {
		return(
			<form onSubmit={this.handleSubmit}>
				{
					this.props.alerta ? <div className="alert alert-danger">{this.props.alerta}</div> : ''
				}
				<FormGroup>
					<Label for="email">E-mail</Label>		
					<Input type="text" value={this.state.email} onChange={this.handleChange} name="email" id="email" required />
				</FormGroup>
				<FormGroup>
					<Label for="senha">Senha</Label>		
					<Input type="password" value={this.state.senha} onChange={this.handleChange} name="senha" id="senha" required />
				</FormGroup>
				<p><a href="http://app.consultafacilthe.com.br/#/esqueci-minha-senha" onClick={() => setTimeout(() => window.location.reload(true), 700)}>Esqueci minha senha</a></p>
				<button className="btn btn-lg btn-block btn-success" type="submit">ENTRAR</button>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	alerta: state.app.alerta
});

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		autenticaUsuario
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(loginForm);