import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import axiosAuth from '../../helpers/axiosAuth';
import Load from '../layout/load';

class RecuperarSenhaForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hash: '',
            loading: true,
            senha: '',
            senha_confirma: '',
            sending: false,
            alerta: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.alteraSenha = this.alteraSenha.bind(this);
    }

    componentDidMount() {
        const hash = window.location.hash.replace(/^.+hash=/, '');
        axiosAuth.get(`usuario/recupera_senha/${hash}`)
            .then(response => {
                this.setState({hash});
                if (!response.data) {
                    window.location.href = '/';
                    window.location.reload();
                }
                this.setState({loading: false});
            })
            .catch(err => {
                this.setState({loading: false, alerta: 'Ocorreu um erro ao se comunicar com o servidor da aplicação.'});
            });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    alteraSenha(event) {
        event.preventDefault();
        this.setState({sending: true});
        axiosAuth.post('usuario/recupera_senha', {
            senha: this.state.senha,
            senha_confirma: this.state.senha_confirma,
            hash: this.state.hash,
        }).then(response => {
            if (response.data.status === false) {
                this.setState({alerta: response.data.message});
            } else {
                alert('Senha alterada com sucesso!');
                this.setState({senha: '', senha_confirma: ''});
                window.location.href = '/';
                window.location.reload();
            }
            this.setState({sending: false});
        }).catch(err => {
            this.setState({sending: false, alerta: 'Ocorreu um erro ao tentar atualizar sua senha! Por favor verifique o preenchimento dos campos do formulário.'});
        });
    }

    render() {
        return(
            <form onSubmit={this.alteraSenha}>
                { this.state.loading ? <Load /> : '' }
                {
					this.state.alerta ? <div className='alert alert-danger'>{this.state.alerta}</div> : ''
				}
                <FormGroup>
                    <Label for="senha">Nova Senha</Label>
                    <Input type="password" name="senha" id="senha" minLength="6" value={this.state.senha} onChange={this.handleChange} required disabled={this.state.sending} />
                </FormGroup>
                <FormGroup>
                    <Label for="senha_confirma">Confirme a Nova Senha</Label>
                    <Input type="password" name="senha_confirma" id="senha_confirma" minLength="6" value={this.state.senha_confirma} onChange={this.handleChange} required disabled={this.state.sending} />
                </FormGroup>
                <button className="btn btn-lg btn-block btn-success" type="submit" disabled={this.state.sending}>ALTERAR SENHA</button>
            </form>
        );
    }
}

export default RecuperarSenhaForm;