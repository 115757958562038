import { pfFormSchema } from '../pessoaFisica/pfForm';
import { pjFormSchema } from '../pessoaJuridica/pjForm';
import { enderecoFormSchema } from '../endereco/enderecoForm';
import { changeInput } from '../form/formReducer';

const INITIAL_STATE = {
	email: '',
	senha: '',
	senha_confirma: '',
	nome: '',
	status: 1,
	tipo: '',
	imagem: '',
	banco: '',
	agencia: '',
	conta: '',
	horario_funcionamento: '',
	ciclo_pagamento: '30',
	data_fechamento_ciclo: '',
	cpf: '',
	id_pessoa: '',
	pf: pfFormSchema,
	pj: pjFormSchema,
	endereco: enderecoFormSchema,
	disabledPF: false,
	disabledPJ: false,
	disabledCPF: false,
	disabledCNPJ: false,
	isLoaded: false
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'PARCEIRO_CHANGE': 
			return changeInput(state, action);
		case 'PARCEIRO_UPDATE':
			return changeInput(state, action);
		case 'PARCEIRO_GET':
			return {...action.payload};
		case 'PARCEIRO_VIEW':
			return action.payload;
		case 'PARCEIRO_CLEAR':
			return INITIAL_STATE;
		case 'PARCEIRO_PF_GET':
			const dataPF = {};
			if (action.payload.status === false) {
				dataPF.disabledPF = false;
				dataPF.pf = { ...INITIAL_STATE.pf , cpf: state.cpf };
				dataPF.id_pessoa = '';
			} else {
				dataPF.pf = action.payload[0];
				dataPF.id_pessoa = action.payload[0].id_pessoa;
			}
			dataPF.disabledCPF = false;
			return { ...state, ...dataPF };
		case 'PARCEIRO_PJ_GET':
			const dataPJ = {};
			if (action.payload.status === false) {
				dataPJ.disabledPJ = false;
				dataPJ.pj = { ...INITIAL_STATE.pj, cnpj: state.cnpj };
				dataPJ.id_pessoa = '';
			} else {
				dataPJ.pj = action.payload[0];
				dataPJ.id_pessoa = action.payload[0].id_pessoa;
			}
			dataPJ.disabledCPF = false;
			return { ...state, ...dataPJ };
		case 'PARCEIRO_PF_CLEAR':
			return { ...state, pf: INITIAL_STATE.pf, id_pessoa: '' };
		case 'PARCEIRO_PJ_CLEAR':
			return { ...state, pj: INITIAL_STATE.pj, id_pessoa: '' };
		case 'PARCEIRO_TOGGLE_CPF':
			return { ...state, disabledCPF: !state.disabledCPF };
		case 'PARCEIRO_TOGGLE_CNPJ':
			return { ...state, disabledCNPJ: !state.disabledCNPJ };
		default:
			return state;
	}
};