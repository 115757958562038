import React from 'react';
import { Route } from 'react-router-dom';
import { LoginIndex } from '../components/login/loginPages';
import { RecuperaSenha, EsqueciMinhaSenha } from '../components/usuario/usuarioPages';

class PrivateRoute extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			publicComponent: LoginIndex
		};
	}

	componentDidMount() {
		switch (window.location.hash.replace(/#\/|\?.+/g, '')) {
			case 'recupera-senha':
				this.setState({publicComponent: RecuperaSenha });
				break;
			case 'esqueci-minha-senha':
				this.setState({publicComponent: EsqueciMinhaSenha });
				break;
			default: 
				this.setState({publicComponent: LoginIndex });
		}
	}

	render() {
		const { component: Component, ...rest } = this.props;
		const { publicComponent: PublicComponent } = this.state;

		return (
			<Route
				render={props =>
					localStorage.getItem('token') ? (
						<Component {...this.props} />
					) : (
						<PublicComponent {...this.props} />
					)
				}
			/>
		);
	}
}

export default PrivateRoute;