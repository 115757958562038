import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axiosAuth from '../../helpers/axiosAuth';
import { alertErro } from '../layout/modalAlertActions';
import { FormGroup, Button, Label, Input, Row, Col } from 'reactstrap';
import Load from '../layout/load';

class UsuarioPerfil extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			senha: '',
			senha_confirma: ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.attUsuario = this.attUsuario.bind(this);
	}

	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}

	attUsuario(event) {
		event.preventDefault();
		axiosAuth.put(`usuario/senha`, this.state)
			.then(response => {
				if (response.data.status === false) {
					this.props.alertErro(response.data.erros);
				} else {
					alert('Senha alterada com sucesso!');
					this.setState({senha: '', senha_confirma: ''});
				}
			}).catch(err => {
				if (err) {
					this.props.alertErro('Ocorreu um erro ao processar a requisição. Por favor, tente novamente!');
				}
			});
	}

	render() {
		return (
			<form onSubmit={this.attUsuario}>
				{this.props.perfil.nome ? '' : <Load />}
				<fieldset>
					<legend>Meus Dados</legend>
					<FormGroup>
						<Label for="nome">Nome</Label>
						<Input type="text" name="nome" id="nome" value={this.props.perfil.nome} />
					</FormGroup>
					<Row>
						<Col>
							<FormGroup>
								<Label for="email">E-mail</Label>
								<Input type="email" name="email" id="email" value={this.props.perfil.email} disabled />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label for="senha">Senha</Label>
								<Input type="password" name="senha" id="senha" value={this.state.senha} onChange={this.handleChange} />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="senha_confirma">Confirma Senha</Label>
								<Input type="password" name="senha_confirma" id="senha_confirma" value={this.state.senha_confirma} onChange={this.handleChange} />
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
				<Button type="submit" outline color="primary">Salvar</Button>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	perfil: state.app.perfil
});

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		alertErro
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioPerfil);