import { formatDecimal } from "../../helpers/util";

/*export const handleChange = (prefix) => (event) => {
	const target = event.target;

	if (target) {
		const value = target.type === 'checkbox' ? target.checked : target.value;
		
		if (target.name.includes('.')) {
			const names = target.name.split('.');

			return { 
				type: prefix + '_CHANGE',
				payload: {
					[names[0]]: {[names[1]]: value}
				}
			};
		}

		return { 
			type: prefix + '_CHANGE',
			payload: {
				[target.name]: value
			}
		};
	}
}*/

export const handleChange = (prefix) => (event, value=null) => {
	const target = event.target;

	if (target) {
		if (value === null) {
			value = target.type === 'checkbox' ? target.checked : target.value;
		}
		
		if (target.name.includes('.')) {
			const names = target.name.split('.');

			return { 
				type: prefix + '_CHANGE',
				objName: names[0],
				payload: {
					[names[0]]: {[names[1]]: value}
				}
			};
		}

		return { 
			type: prefix + '_CHANGE',
			payload: {
				[target.name]: value
			}
		};
	} else if (event && value) {
		return { 
			type: prefix + '_CHANGE',
			payload: {
				[event]: value
			}
		};
	}
}

export const handleDecimalChange = (prefix) => (event, value=null, cb=()=>{}) => {
	const target = event.target;
	const name = target ? target.name : event;
	
	value = target ? formatDecimal(target.value) : formatDecimal(value);
	cb(value);

	return { 
		type: prefix + '_CHANGE',
		payload: {
			[name]: value
		}
	};
}

export const handleSelectChange = (prefix) => (name, selectedOption) => {
	return { 
		type: prefix + '_CHANGE',
		payload: {
			[name]: selectedOption ? selectedOption : null
		}
	};
}

export const handleUpdate = (prefix) => (data={}, objName=null) => {
	return objName ? {
		type: prefix + '_UPDATE',
		objName,
		payload: {[objName]: data}
	} : {
		type: prefix + '_UPDATE',
		payload: data
	};
}

export const clearForm = (prefix) => {
	return { type: prefix + '_CLEAR' };
}