import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import axiosAuth from '../../helpers/axiosAuth';

class EsqueciSenhaForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            sending: false,
            alerta: '',
            error: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.enviaEmail = this.enviaEmail.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    enviaEmail(event) {
        event.preventDefault();
        this.setState({sending: true});
        axiosAuth.post('usuario/recupera_senha', {
            email: this.state.email
        }).then(response => {
            if (response.data === false) {
                this.setState({error: true, alerta: 'Email não cadastrado.'});
            } else {
                this.setState({error: false, alerta: 'Email enviado com sucesso!', email: ''});
            }
            this.setState({sending: false});
        }).catch(err => {
            this.setState({error: true, alerta: 'Ocorreu um erro ao processar sua requisição! Por favor verifique o preenchimento dos campos do formulário e tente novamente.'});
            this.setState({sending: false});
        });
    }

    render() {
        return(
            <form onSubmit={this.enviaEmail}>
                {
					this.state.alerta ? <div className={`alert alert-${this.state.error?'danger':'primary'}`}>{this.state.alerta}</div> : ''
				}
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email" name="email" id="email" minLength="6" value={this.state.email} onChange={this.handleChange} required disabled={this.state.sending} />
                </FormGroup>
                <button disabled={this.state.sending} className="btn btn-lg btn-block btn-success" type="submit">ENVIAR EMAIL</button>
            </form>
        );
    }
}

export default EsqueciSenhaForm;