import { changeInput } from '../form/formReducer';

const INITIAL_STATE = {
	observacao: '',
	nome: '',
	nome_parceiro_procedimento: '',
	codigo: '',
	comissao: 0,
	valor_parceiro: 0,
	horario_atendimento: '',
	id_parceiro: '',
	id_procedimento: '',
	id_parceiro_procedimento: '',
	isLoaded: false,
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'PARCEIRO_PROCEDIMENTO_CHANGE': 
			return changeInput(state, action);
		case 'PARCEIRO_PROCEDIMENTO_GET':
			return { ...action.payload };
		case 'PARCEIRO_PROCEDIMENTO_CLEAR':
			return { ...INITIAL_STATE, id_parceiro: state.id_parceiro };
		default:
			return state;
	}
};