import { combineReducers } from 'redux';

import agendamentoFormReducer from './components/agendamento/agendamentoFormReducer';
import clienteFormReducer from './components/cliente/clienteFormReducer';
import financeiroFormReducer from './components/financeiro/financeiroFormReducer';
import parceiroFormReducer from './components/parceiro/parceiroFormReducer';
import parceiroProcedimentoFormReducer from './components/parceiro/parceiroProcedimentoFormReducer';
import procedimentoFormReducer from './components/procedimento/procedimentoFormReducer';
import usuarioFormReducer from './components/usuario/usuarioFormReducer';
import pagesReducer from './ducks/pages';
import appReducer from './ducks/app';
import modalAlertReducer from './components/layout/modalAlertReducer';
// import enderecoFormReducer from './components/endereco/enderecoFormReducer';
// import parceiroReducer from './components/parceiro/parceiroReducer';
// import pfReducer from './components/pessoaFisica/pfReducer';
// import pjReducer from './components/pessoaJuridica/pjReducer';

const rootReducer = combineReducers({
	agendamentoForm: agendamentoFormReducer,
	clienteForm: clienteFormReducer,
	financeiroForm: financeiroFormReducer,
	parceiroForm: parceiroFormReducer,
	parceiroProcedimentoForm: parceiroProcedimentoFormReducer,
	procedimentoForm: procedimentoFormReducer,
	usuarioForm: usuarioFormReducer,
	modalAlert: modalAlertReducer,
	pages: pagesReducer,
	app: appReducer
});

export default rootReducer;