import { pfFormSchema } from '../pessoaFisica/pfForm';
import { changeInput } from '../form/formReducer';

const INITIAL_STATE = {
	pf: pfFormSchema,
	email: '',
	tipo: '',
	senha: '',
	senha_confirma: '',
	cpf: '',
	id_pessoa: '',
	disabledPF: false,
	disabledCPF: false,
	isLoaded: false
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'USUARIO_CHANGE': 
			return changeInput(state, action);
		case 'USUARIO_UPDATE': 
			return changeInput(state, action);
		case 'USUARIO_GET':
			return { ...action.payload };
		case 'USUARIO_PF_GET':
			const data = {};
			if (action.payload.status === false) {
				data.disabledPF = false;
				data.pf = { cpf: state.cpf };
				data.id_pessoa = '';
			} else {
				data.pf = action.payload[0];
				data.id_pessoa = action.payload[0].id_pessoa;
			}
			data.disabledCPF = false;
			return { ...state, ...data };
		case 'USUARIO_PF_CLEAR':
			return { ...state, pf: INITIAL_STATE.pf, id_pessoa: '' };
		case 'USUARIO_VIEW':
			return action.payload;
		case 'USUARIO_CLEAR':
			return INITIAL_STATE;
		case 'USUARIO_TOGGLE_CPF':
			return { ...state, disabledCPF: !state.disabledCPF };
		default:
			return state;
	}
};