import { formatDecimal } from "../../helpers/util";

const INITIAL_STATE = {
	cliente_cpf: '',
	cliente_nome: '',
	id_cliente: '',
	parceiroProcedimentoById: [],
	procedimento: '',
	representante: '',
	procedimento_codigo: '',
	procedimento_quantidade: 1,
	procedimentos_filtrados: [],
	procedimentos: [],
	valor_pago_cartao: '',
	valor_adicional_cartao: '',
	tipo_cartao: '',
	valor_representante: '',
	valor_pago_especie: '',
	created_by: '',
	disabledProcedimentos: true
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'AGENDAMENTO_CHANGE': 
			return {...state, ...action.payload};
		case 'AGENDAMENTO_ADD_PROCEDIMENTO':
			if (state.procedimento && state.procedimento_codigo.value) {
				var procedimentos = state.procedimentos.filter(p => {
					return p.id_parceiro_procedimento !== state.procedimento_codigo.value;
				}).concat({
					id_parceiro_procedimento: state.procedimento_codigo.value, 
					id_procedimento: state.procedimento,
					id_parceiro: state.parceiroProcedimentoById[state.procedimento_codigo.value].id_parceiro,
					quantidade: state.procedimento_quantidade,
					desconto: 0
				});
				return {...state, procedimentos: procedimentos, procedimento: '', procedimento_codigo: '', procedimento_quantidade: 1};
			}
			return state;
		case 'AGENDAMENTO_REMOVE_PROCEDIMENTO':
			var procedimentos = state.procedimentos.filter(p => {
				return p.id_parceiro_procedimento !== action.payload;
			});
			return {...state, procedimentos: procedimentos};
		case 'AGENDAMENTO_GET_CLIENTE':
			var cliente = {
				id_cliente: action.payload.id_cliente,
				cliente_nome: action.payload.nome 
			};
			return {...state, ...cliente};
		case 'AGENDAMENTO_CHANGE_DESCONTO':
			var procedimentos = [].concat(state.procedimentos),
				value = action.payload.target.value,
				max = action.payload.target.max*1;

			procedimentos[action.payload.index].desconto = (isNaN(value) || value < 0)? 0 : (value > max)? max : value;

			return {...state, procedimentos}
		case 'AGENDAMENTO_CLEAR_CLIENTE':
			return {...state, cliente_nome: action.payload || '', id_cliente: ''};
		case 'AGENDAMENTO_FILTER_PROCEDIMENTO':
			return {...state, procedimentos_filtrados: action.payload, procedimento_codigo: ''};
		case 'AGENDAMENTO_CLEAR':
			return {...INITIAL_STATE, parceiroProcedimentoById: state.parceiroProcedimentoById};
		default:
			return state;
	}
};