import React from 'react';

class PFSingle extends React.Component {

	render() {
		return (
			<div>
				<h3>Pessoa Física</h3>
				<dl className="row">
					<dt className="col-sm-3">Nome</dt>
					<dd className="col-sm-9">{this.props.pf.nome}</dd>

					<dt className="col-sm-3">Nome social</dt>
					<dd className="col-sm-9">{this.props.pf.nome_social}</dd>

					<dt className="col-sm-3">Data de nascimento</dt>
					<dd className="col-sm-9">{this.props.pf.data_nascimento && this.props.pf.data_nascimento.replace(/^(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')}</dd>

					<dt className="col-sm-3">CPF</dt>
					<dd className="col-sm-9">{this.props.pf.cpf && this.props.pf.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</dd>

					<dt className="col-sm-3">RG</dt>
					<dd className="col-sm-9">{this.props.pf.rg}</dd>

					<dt className="col-sm-3">Sexo</dt>
					<dd className="col-sm-9">{['Feminino', 'Masculino'][this.props.pf.sexo]}</dd>

					<dt className="col-sm-3">E-mail</dt>
					<dd className="col-sm-9">{this.props.pf.email}</dd>

					<dt className="col-sm-3">Telefone</dt>
					<dd className="col-sm-9">{this.props.pf.telefone}</dd>

					<dt className="col-sm-3">Telefone 2</dt>
					<dd className="col-sm-9">{this.props.pf.telefone2}</dd>

					<dt className="col-sm-3">Nome do pai</dt>
					<dd className="col-sm-9">{this.props.pf.pai}</dd>

					<dt className="col-sm-3">Nome da mãe</dt>
					<dd className="col-sm-9">{this.props.pf.mae}</dd>

					<dt className="col-sm-3">Nome do responsável</dt>
					<dd className="col-sm-9">{this.props.pf.responsavel}</dd>
				</dl>
			</div>
		);
	}
}

export default PFSingle;