export const changeInput = (state, action) => {
	const newState = {...state};

	if (action.objName) {
		newState[action.objName] = {...newState[action.objName], ...action.payload[action.objName]};
	} else {
		return {...newState, ...action.payload};
	}

	return newState;
}