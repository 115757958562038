const INITIAL_STATE = {
	tipo: '',
	codigo_tuss: '',
	codigo_especialidade: '',
	nome: ''
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'PROCEDIMENTO_CHANGE':
			return {...state, ...action.payload};
		case 'PROCEDIMENTO_GET':
			return {...action.payload};
        case 'PROCEDIMENTO_CLEAR':
            return INITIAL_STATE;
		default:
			return state;
	}
};