import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import PrivateRoute from './helpers/privateRoute';

import { Provider } from 'react-redux';

import rootStore from './store';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import Load from './components/layout/load';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import './assets/css/style.css';
// import registerServiceWorker from './registerServiceWorker';

library.add(fas);
const App = React.lazy(() => import('./App'));

ReactDOM.render(
	<Provider store={rootStore}>
		<HashRouter>
			<Suspense fallback={<Load />}>
				<PrivateRoute path="/" component={App} />
			</Suspense>
		</HashRouter>
	</Provider>
, document.getElementById('root'));

// registerServiceWorker();