// import React from 'react';
// import { dispatch } from 'redux';
import axios from 'axios';
// import configureStore from '../store';
// import { alertErro } from '../components/layout/modalAlertActions';

// const store = rootStore();

const axiosAuth = axios.create({
	baseURL: 'https://app.consultafacilthe.com.br/api',
	timeout: 35000,
	headers: {
		'CF-API-KEY': localStorage.getItem('token'),
		'CF-UID': localStorage.getItem('uid')
	}
});

axiosAuth.interceptors.response.use(function (response) {
	if (response.data.status === false) {
		// var store = configureStore();
		// store.alertErro(response.data.message);
	}
	return response;
}, function (error) {
	if (error.response) {
		if (error.response.status === 403 || error.response.status === 401) {
			window.location.href = '/#/logout';
		}
	}
	return Promise.reject(error);
});

export default axiosAuth;