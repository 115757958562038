import axiosAuth from '../../helpers/axiosAuth';

export const getUsuario = (id = '', cb = function(){}) => {
	const usuarioPromise = axiosAuth.get(`usuario/${id}`); 

	return dispatch => {
		usuarioPromise.then(response => {
			dispatch({ type: 'USUARIO_GET', payload: response.data[0] });
			cb();
		});
	}
}

export const getUsuarioPF = (cpf = '') => {
	const pfPromise = axiosAuth.get(`pessoa-fisica/${cpf.replace(/\.|-/g, '')}`); 

	return dispatch => {
		pfPromise.then(response => dispatch({ type: 'USUARIO_PF_GET', payload: response.data }));
	}
}

export const clearUsuarioPF = () => {
	return { type: 'USUARIO_PF_CLEAR' };
}

export const toggleCPF = () => {
	return { type: 'USUARIO_TOGGLE_CPF' };
}