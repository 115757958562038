import axiosAuth from '../helpers/axiosAuth';

const INITIAL_STATE = {
	alerta: '',
	perfil: {
		nome: '',
		email: '',
		ulvl: ''
	},
	cidadesByUF: [],
	parceirosOptions: [],
	procedimentosById: [],
	procedimentosOptions: [],
	procedimentosAgendamentoOptions: [],
	representantesOptions: [],
	codigosProcedimentos: []
};

export default function reducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'APP_CHANGE':
			return {...state, ...action.payload};
		case 'PERFIL_GET':
			return {...state, perfil: action.payload};
		case 'CIDADES_GET':
			return {...state, cidadesByUF: action.payload};
		case 'PARCEIROS_OPTIONS_GET':
			return {...state, parceirosOptions: action.payload};
		case 'REPRESENTANTES_OPTIONS_GET':
			return {...state, representantesOptions: action.payload};
		case 'PROCEDIMENTOS_GET':
			return {...state, procedimentosById: action.payload};
		case 'PROCEDIMENTOS_OPTIONS_GET':
			return {...state, procedimentosOptions: action.payload};
		case 'PROCEDIMENTOS_AGENDAMENTO_OPTIONS_GET':
			return {...state, procedimentosAgendamentoOptions: action.payload};
		case 'PROCEDIMENTOS_CODIGOS_GET':
			return {...state, codigosProcedimentos: action.payload};
		case 'CLEAR_APP':
			return INITIAL_STATE;
		default:
			return state;
	}
}

export const getPerfil = (cb = function(){}) => {
	const request = axiosAuth.get('usuario/info');

	return dispatch => {
		request.then(response => {
			cb(response.data[0]);
			return dispatch({ type: 'PERFIL_GET', payload: { nome: response.data[0].nome, email: response.data[0].email, ulvl: response.data[0].level} });
		});
	}
}

export const getCidades = (cb = function(){}) => {
	const request = axiosAuth.get('cidade/');

	return dispatch => { 
		request.then(response => {
			dispatch({ type: 'CIDADES_GET', payload: response.data });
			cb();
		});
	}
}

export const getProcedimentos = () => {
	const request = axiosAuth.get('procedimento/');

	return dispatch => {
		request.then(response => {
			const procedimentosById = response.data.reduce((procedimentos, p) => {
				procedimentos[p.id_procedimento] = p;
				return procedimentos;
			}, {});
			dispatch({ type: 'PROCEDIMENTOS_GET', payload: procedimentosById });
			return response;
		}).then(response => {
			const procedimentosOptions = response.data.reduce((procedimentos, p) => {
				procedimentos.push({value: p.id_procedimento, label: `${p.codigo_tuss} - ${p.nome}`});
				return procedimentos;
			}, []);
			dispatch({ type: 'PROCEDIMENTOS_OPTIONS_GET', payload: procedimentosOptions });
		});
	}
}

export const getProcedimentosAgendamento = () => {
	const request = axiosAuth.get('procedimento/options');

	return dispatch => {
		request.then(response => {
			if (response.data.status !== false) {
				const procedimentosOptions = response.data.reduce((procedimentos, p) => {
					procedimentos.push({value: p.id_procedimento, label: `${p.codigo_tuss} - ${p.nome}`});
					return procedimentos;
				}, []);
				dispatch({ type: 'PROCEDIMENTOS_AGENDAMENTO_OPTIONS_GET', payload: procedimentosOptions });
			}
		});
	}
}

export const getParceirosOptions = () => {
	const request = axiosAuth.get('parceiro/financeiro');

	return dispatch => {
		request.then(response => {
			const parceirosOptions = response.data.reduce((parceiros, p) => {
				parceiros.push({value: p.id_parceiro, label: `${p.nome} - ${p.cpf ? p.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : p.cnpj ? p.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : ''} - R$ ${p.total_parceiro}`});
				return parceiros;
			}, []);
			dispatch({ type: 'PARCEIROS_OPTIONS_GET', payload: parceirosOptions });
		});
	}
}

export const getRepresentantesOptions = () => {
	const request = axiosAuth.get('representante');

	return dispatch => {
		request.then(response => {
			const representantesOptions = response.data.reduce((representantes, p) => {
				representantes.push({
					value: p.id_representante, 
					label: `${p.nome} - ${p.cpf && p.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}`
				});
				return representantes;
			}, []);
			dispatch({ type: 'REPRESENTANTES_OPTIONS_GET', payload: representantesOptions });
		});
	}
}

/*export const getCodigosProcedimentos = () => {
	const request = axiosAuth.get('procedimento/distinct');

	return dispatch => {
		request.then(response => {
			const codigos = response.data.map(proc => ({value: proc.codigo_tuss, label: proc.codigo_tuss}));
			dispatch({ type: 'PROCEDIMENTOS_CODIGOS_GET', payload: codigos });
		});
	}
}*/

export const logout = () => {
	const request = axiosAuth.get('logout');

	localStorage.removeItem('token');
	localStorage.removeItem('uid');
	localStorage.removeItem('email');

	return dispatch => {
		request.then(response => {
			if (response.data.status === true) {
				dispatch({ type: 'CLEAR_APP' });
			} 
		})
	}
}