import React from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import InputMask from 'react-input-mask';
import axiosAuth from '../../helpers/axiosAuth';
import FormGroupLoading from '../form/formGroupLoading';

class pjForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchingCNPJ: false,
			cnpjOnly: props.pj ? false : true,
			disabled: false
		};

		this.changeCNPJ = this.changeCNPJ.bind(this);
		this.handleBlurCNPJ = this.handleBlurCNPJ.bind(this);
	}

	changeCNPJ(event) {
		this.props.change(event);
		const cnpj = event.target.value.replace(/\.|-|\//g, '');
		
		if (cnpj.length === 14) {
			this.setState({disabled: true, searchingCNPJ: true});
			axiosAuth.get(`pessoa-juridica/check/${cnpj}`)
				.then(response => {
					if (this.props.cbCNPJ) {
						this.props.cbCNPJ(response.data);
					}
					if (response.data.status === false) {
						this.setState({disabled: false});
					}
					this.setState({cnpjOnly: false, searchingCNPJ: false});
				}).catch(err => {
					alert('Ocorreu ao realizar a consulta. Por favor tente novamente!');
					this.setState({disabled: false, searchingCNPJ: false});
				});
		}
	}

	handleBlurCNPJ(event) {
		if(event.target.value.length > 0 && event.target.value.length !== 18) {
			alert('CNPJ incompleto!');	
			this.props.change({target:{name:'pj.cnpj'}}, '');
			event.target.focus();
		}
	}

	render() {
		return (
			<fieldset>
				<legend>Dados Pessoa Jurídica</legend>
				{
					this.state.cnpjOnly ? (
						<Row>
							<Col sm="4">
								<FormGroupLoading loading={this.state.searchingCNPJ}>
									<Label for="cnpj">CNPJ</Label>
									<InputMask mask="99.999.999/9999-99" maskChar="" name="pj.cnpj" id="cnpj" className="form-control" value={this.props.pj.cnpj} required onChange={this.changeCNPJ} disabled={this.props.disabled} onBlur={this.handleBlurCNPJ} />
								</FormGroupLoading>
							</Col>
						</Row>
					) : (
						<>
						<Row>
							<Col>
								<FormGroup>
									<Label for="razao_social">Razão Social</Label>
									<Input type="text" name="pj.razao_social" id="razao_social" value={this.props.pj.razao_social} required onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="nome_fantasia">Nome Fantasia</Label>
									<Input type="text" name="pj.nome_fantasia" id="nome_fantasia" value={this.props.pj.nome_fantasia} required onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label for="cnpj">CNPJ</Label>
									<InputMask mask="99.999.999/9999-99" maskChar="" name="pj.cnpj" id="cnpj" className="form-control" value={this.props.pj.cnpj} required onChange={this.changeCNPJ} disabled={this.props.disabled} onBlur={this.handleBlurCNPJ} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="email">E-mail</Label>
									<Input type="text" name="pj.email" id="email" value={this.props.pj.email} required onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="telefone">Telefone</Label>
									<Input type="text" name="pj.telefone" id="telefone" value={this.props.pj.telefone} required maxLength="13" onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="telefone2">Telefone 2</Label>
									<Input type="text" name="pj.telefone2" id="telefone2" value={this.props.pj.telefone2} required maxLength="13" onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label for="nome_responsavel">Nome do responsável</Label>
							<Input type="text" name="pj.nome_responsavel" id="nome_responsavel" value={this.props.pj.nome_responsavel} required onChange={this.props.change} disabled={this.props.disabled} />
						</FormGroup>
						<Row>
							<Col>
								<FormGroup>
									<Label for="cpf_responsavel">CPF do responsável</Label>
									<Input type="text" name="pj.cpf_responsavel" id="cpf_responsavel" value={this.props.pj.cpf_responsavel} required maxLength="11" onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="telefone_responsavel">Telefone do responsável</Label>
									<Input type="text" name="pj.telefone_responsavel" id="telefone_responsavel" value={this.props.pj.telefone_responsavel} required onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="email_responsavel">E-mail do responsável</Label>
									<Input type="email" name="pj.email_responsavel" id="email_responsavel" value={this.props.pj.email_responsavel} required onChange={this.props.change} disabled={this.props.disabled} />
								</FormGroup>
							</Col>
						</Row>
						</>
					)
				}
			</fieldset>
		);
	}
}

export default pjForm;

export const pjFormSchema = {
	'id_pessoa_juridica': '',
	'razao_social': '',
	'nome_fantasia': '',
	'cnpj': '',
	'email': '',
	'telefone': '',
	'telefone2': '',
	'nome_responsavel': '',
	'cpf_responsavel': '',
	'telefone_responsavel': '',
	'email_responsavel': ''
};