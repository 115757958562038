import React from 'react';
import axiosAuth from '../../helpers/axiosAuth';
import PFSingle from '../pessoaFisica/pfSingle';
import FloatButton from '../layout/floatButton';
import Load from '../layout/load';

class UsuarioSingle extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			status: '',
			ultimo_acesso: '',
			pf: {},
			isLoaded: false
		};
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.id) {
			axiosAuth.get(`usuario/${this.props.match.params.id}`)
				.then(response => {
					this.setState({ isLoaded: true, ...response.data[0] });
				});
		}
	}

	render() {
		return (
			<div>
				{this.state.isLoaded ? '' : <Load />}
				<dl className="row">
					<dt className="col-sm-3">E-mail</dt>
					<dd className="col-sm-9">{this.state.email}</dd>
					<dt className="col-sm-3">Status</dt>
					<dd className="col-sm-9">{['inativo', 'ativo'][this.state.status]}</dd>
					<dt className="col-sm-3">Último acesso</dt>
					<dd className="col-sm-9">{this.state.ultimo_acesso && this.state.ultimo_acesso.replace(/(\w{4})-(\w{2})-(\w{2}) (\w)/, '$3/$2/$1 $4')}</dd>
				</dl>
				<PFSingle pf={this.state.pf} />
				<FloatButton onClick={false} icon="pencil-alt" title="Editar" to={`/usuario/e/${this.props.match.params.id}`} />
			</div>
		);
	}
}

export default UsuarioSingle;