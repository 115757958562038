import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axiosAuth from '../../helpers/axiosAuth';
import { FormText, FormGroup, Button, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select'
import PFForm from '../pessoaFisica/pfForm';
import { getUsuario } from './usuarioActions';
import { handleChange, handleSelectChange, handleUpdate, clearForm } from '../form/formActions';
import { clearState } from '../../ducks/pages';
import FloatButton from '../layout/floatButton';
import Load from '../layout/load';
import { alertErro } from '../layout/modalAlertActions';

class UsuarioFormEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: props.disabled,
            tipos: [
				{value: 3, label: 'Funcionário'},
				{value: 2, label: 'Coordenador'},
				{value: 1, label: 'Administrador'}
			]
        };

        this.updateUsuario = this.updateUsuario.bind(this);
    }

    componentDidMount() {
        this.props.clear('USUARIO');

		if (this.props.match && this.props.match.params.id) {
			this.props.getUsuario(this.props.match.params.id, () => this.props.change('isLoaded', true));
		}
    }

    updateUsuario(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return false;
        }
        this.setState({disabled: true});

        const usuario = {
            id_usuario: this.props.match.params.id,
            email: this.props.email,
            tipo: this.props.tipo.value,
            senha: this.props.senha,
			senha_confirma: this.props.senha_confirma,
            pf: {...this.props.pf}
        };

        axiosAuth.put('usuario', usuario)
            .then(response => {
                if (response.data.status === false) {
					this.props.alertErro(response.data.message);
					this.setState({disabled: false});
				} else {
					this.props.clear('USUARIO');
					this.props.clearState('usuarios');
					alert('Usuário atualizado com sucesso!');
					window.location.href = `/#/usuario/v/${this.props.match.params.id}`;
				}
            })
            .catch(err => {
                this.props.alertErro('Ocorreu um erro ao processar sua requisição. Por favor tente novamente!')
				this.setState({disabled: false});
            });
    }

    render() {
        return (
            <form onSubmit={this.updateUsuario}>
				{this.props.isLoaded ? '' : <Load />}
				<PFForm pf={this.props.pf} change={this.state.disabled ? undefined : this.props.change} disabled={this.state.disabled} />
                <fieldset>
					<legend>Dados Acesso</legend>
					<Row>
						<Col>
							<FormGroup>
								<Label for="email_usuario">E-mail</Label>
								<Input type="email" name="email" id="email_usuario" value={this.props.email} onChange={this.props.change} required />
								<FormText>E-mail utilizado para login.</FormText>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="tipo">Tipo</Label>
								<Select options={this.state.tipos} value={this.props.tipo} onChange={(selectedOption) => this.props.changeSelect('tipo', selectedOption)} name="tipo" id="tipo" placeholder="Selecione..." noResultsText="Nenhum resultado encontrado" />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label for="senha">Senha</Label>
								<Input type="password" name="senha" id="senha" minLength="6" value={this.props.senha} onChange={this.props.change} required />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="senha_confirma">Confirma Senha</Label>
								<Input type="password" name="senha_confirma" id="senha_confirma" minLength="6" value={this.props.senha_confirma} onChange={this.props.change} required />
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
				<Button type="submit" outline color="primary" disabled={this.state.disabled}>Salvar</Button>
				<FloatButton onClick={false} icon="arrow-left" title="Voltar" to={`/usuario/v/${this.props.match.params.id}`} />
			</form>
        );
    }
}

const mapStateToProps = (state) => ({
    email: state.usuarioForm.email,
    senha: state.usuarioForm.senha,
    senha_confirma: state.usuarioForm.senha_confirma,
    tipo: state.usuarioForm.tipo,
    pf: state.usuarioForm.pf,
    isLoaded: state.usuarioForm.isLoaded
});

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        alertErro,
        getUsuario,
        change: handleChange('USUARIO'),
		update: handleUpdate('USUARIO'),
		changeSelect: handleSelectChange('USUARIO'),
		clear: clearForm,
		clearState
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFormEdit);