import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPerfil } from '../../ducks/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Page extends React.Component {

	componentDidMount() {
		if (localStorage.getItem('token') && !this.props.ulvl) {
			this.props.getPerfil((data) => {
				if (data.level < 5) {
					window.location.href = '/parceiro';
				}
			});
		}
	}

	render() {
		return (
			<div className={this.props.className ? `page ${this.props.className}` : 'page'}>
				<div className="page-header">
					<h1 className="page-title">{this.props.icon ? (<FontAwesomeIcon icon={this.props.icon} transform="shrink-4" />) : ''} {this.props.title} { this.props.subtitle ? <small>{this.props.subtitle}</small> : '' }</h1>
				</div>
				{this.props.children}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	ulvl: state.app.perfil.ulvl
});

const mapDispatchToProp = (dispatch) => (
	bindActionCreators({
		getPerfil
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProp)(Page);

export const PublicPage = (props) => (
	<div className="container-fluid" style={{maxWidth: 420}}>
		<div className="text-center mt-5">
			<img src="logo.png" alt="Consulta Fácil" height="70" />
		</div>
		<Page title={props.title} icon={props.icon}>{props.children}</Page>
	</div>
);