import React from 'react';
import { PublicPage } from '../layout/page';

import LoginForm from './loginForm';
import { logout } from '../../ducks/app';

import '../../assets/css/login.css';

const icon = 'sign-in-alt';
const title = 'Login';

export class LoginIndex extends React.Component {

	componentWillMount() {
		if (window.location.hash === '#/logout') {
			window.location.href = '/';
		}
	}

	render() {
		return (
			<PublicPage title={title} icon={icon}>
				<LoginForm />
			</PublicPage>
		);
	}
}

export class Logout extends React.Component {

	componentDidMount() {
		logout();
		setTimeout(() => {window.location.href = '/'}, 1000);
	}

	render() {
		return (
			<PublicPage title="Logout" icon={icon}>
				<p>Deslogando...</p>
			</PublicPage>
		);
	}
}