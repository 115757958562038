import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import axiosAuth from '../../helpers/axiosAuth';
import TableSearch from '../layout/tableSearch';
import FloatButton from '../layout/floatButton';
import Load from '../layout/load';
import { changeState, clearState } from '../../ducks/pages';

class UsuarioList extends TableSearch {

	constructor(props) {
		super(props);

		this.state = {
			columns: [
				{title: 'E-mail', dataIndex: 'email', key: 'email', ...this.getColumnSearchProps('email'), render: (email, row) => (<Link to={`/usuario/v/${row.id_usuario}`}>{email}</Link>)},
				{title: 'Status', dataIndex: 'status', key: 'status', render: (status) => (['inativo', 'ativo'][status])},
				{title: 'Último acesso', dataIndex: 'ultimo_acesso', key: 'ultimo_acesso', render: (ultimo_acesso) => (ultimo_acesso && ultimo_acesso.replace(/(\w{4})-(\w{2})-(\w{2}) (\w)/, '$3/$2/$1 $4'))},
			],
			searchOptions: [
				{name: 'E-mail', value: 'email'}
			],
			searchFilters: [
				{name: 'Status', value: 'status', type: 'select', options: [{value: 1, label: 'Ativo'}, {value: 0, label: 'Inativo'}]}
			],
			isLoaded: true,
		};

		this.filter = this.filter.bind(this);
	}
	
	componentDidMount() {
		if (!this.props.usuarios.length) {
			this.setState({ isLoaded: false });
			axiosAuth.get('usuario')
				.then(response => {
					this.setState({ isLoaded: true });
					if (response.data.status !== false) {
						this.props.changeState({ usuarios: response.data });
					}
				});
		}
	}

	filter(value, field, filters) {
		const url = value ? `usuario/${value}/${field}?${filters}` : `usuario?${filters}`;
		this.setState({ isLoaded: false });
		this.props.clearState('usuarios');
		axiosAuth.get(url)
			.then(response => {
				this.setState({ isLoaded: true });
				if (response.data.status !== false) {
					this.props.changeState({ usuarios: response.data });
				}
			});
	}

	render() {
		return (
			<div>
				{this.state.isLoaded ? '' : <Load />}
				<div className="table-responsive">
					<Table rowKey="id_usuario" columns={this.state.columns} dataSource={this.props.usuarios} />
				</div>
				<FloatButton onClick={false} to="/usuario/adicionar" />	
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	usuarios: state.pages.usuarios
});

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		changeState,
		clearState
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioList);