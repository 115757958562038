import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axiosAuth from '../../helpers/axiosAuth';
import PFForm from '../pessoaFisica/pfForm';

import { getUsuarioPF, clearUsuarioPF, toggleCPF } from './usuarioActions';
import { handleChange, handleSelectChange, clearForm, handleUpdate } from '../form/formActions';
import { alertErro } from '../layout/modalAlertActions';
import { clearState } from '../../ducks/pages';

import { FormText, FormGroup, Button, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';

class UsuarioForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			disabled: props.disabled,
			tipos: [
				{value: 3, label: 'Funcionário'},
				{value: 2, label: 'Coordenador'},
				{value: 1, label: 'Administrador'}
			],
			modal: false
		}

		this.cbCPF = this.cbCPF.bind(this);
		this.addUsuario = this.addUsuario.bind(this);
	}

	componentDidMount() {
		this.props.clear('USUARIO');
	}

	cbCPF(data) {
		if (data.id_usuario) {
			alert('USUÁRIO JÁ CADASTRADO');
			window.location.href = `/#/usuario/v/${data.id_usuario}`;
		} else if (data.id_pessoa) {
			this.setState({disabledPF: true});
			this.props.change({target:{name: 'id_pessoa'}}, data.id_pessoa);
			this.props.update(data.pf, 'pf');
		}
	}

	addUsuario(event) {
		event.preventDefault();

		if (this.state.disabled) {
			return false;
		}

		const usuario = {
			email: this.props.email,
			tipo: this.props.tipo.value,
			senha: this.props.senha,
			senha_confirma: this.props.senha_confirma,
			id_pessoa: this.props.id_pessoa,
			pf: {...this.props.pf}
		};

		this.setState({disabled: true});

		axiosAuth.post('usuario', usuario)
			.then(response => {
				if (response.data.status === false) {
					this.props.alertErro(response.data.message);
				} else {
					this.props.clear('USUARIO');
					this.props.clearState('usuarios');
					alert('Usuário adicionado com sucesso!');
				}
				this.setState({disabled: false});
			})
			.catch(err => {
				this.props.alertErro(err.response.data.erros);
				this.props.toggleCPF();
				this.setState({disabled: false});
			});
	}

	render() {
		return (
			<form onSubmit={this.addUsuario}>
				<PFForm pf={this.props.pf} change={this.state.disabled || this.props.disabledPF ? undefined : this.props.change} cbCPF={this.cbCPF} disabled={this.state.disabled || this.props.disabledPF} />
				<fieldset>
					<legend>Dados Acesso</legend>
					<Row>
						<Col>
							<FormGroup>
								<Label for="email_usuario">E-mail</Label>
								<Input type="email" name="email" id="email_usuario" value={this.props.email} onChange={this.props.change} required />
								<FormText>E-mail utilizado para login.</FormText>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="tipo">Tipo</Label>
								<Select options={this.state.tipos} value={this.props.tipo} onChange={(selectedOption) => this.props.changeSelect('tipo', selectedOption)} name="tipo" id="tipo" placeholder="Selecione..." noResultsText="Nenhum resultado encontrado" />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label for="senha">Senha</Label>
								<Input type="password" name="senha" id="senha" minLength="6" value={this.props.senha} onChange={this.props.change} required />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="senha_confirma">Confirma Senha</Label>
								<Input type="password" name="senha_confirma" id="senha_confirma" minLength="6" value={this.props.senha_confirma} onChange={this.props.change} required />
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
				
				<Button type="submit" outline color="primary" disabled={this.state.disabled}>Salvar</Button>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	pf: state.usuarioForm.pf,
	cpf: state.usuarioForm.cpf,
	email: state.usuarioForm.email,
	tipo: state.usuarioForm.tipo,
	senha: state.usuarioForm.senha,
	senha_confirma: state.usuarioForm.senha_confirma,
	id_pessoa: state.usuarioForm.id_pessoa,
	disabledPF: state.usuarioForm.disabledPF,
	disabledCPF: state.usuarioForm.disabledCPF
});

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		alertErro,
		getUsuarioPF,
		clearUsuarioPF,
		toggleCPF,
		change: handleChange('USUARIO'),
		update: handleUpdate('USUARIO'),
		changeSelect: handleSelectChange('USUARIO'),
		clear: clearForm,
		clearState
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);