export const alertErro = (conteudo) => {
	const data = {
		conteudo: conteudo,
		tipo: 'error',
		titulo: 'Ocorreram os seguintes erros'
	};
	return (dispatch) => {
		dispatch({ type: 'SHOW_MODAL_ALERT', payload: data })
	};
}

export const fechaModalAlert = () => {
	return { type: 'CLEAR_MODAL_ALERT' };
};