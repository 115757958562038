const INITIAL_STATE = {
	id_parceiro: '',
	agendamentos: [],
	parceiroAgendamentos: []
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'FINANCEIRO_CHANGE': 
			return {...state, ...action.payload};
		case 'FINANCEIRO_CLEAR':
			return {...INITIAL_STATE};
		case 'FINANCEIRO_FILTER_AGENDAMENTOS':
			return {...state, parceiroAgendamentos: action.payload, agendamentos: []};
		case 'FINANCEIRO_AGENDAMENTO_CHECK':
			let { agendamentos } = state;
			if (agendamentos.includes(action.payload)) {
				agendamentos = agendamentos.filter( a => a !== action.payload );
			} else {
				agendamentos.push(action.payload);
			}
			return {...state, agendamentos};
		default:
			return state;
	}
};