import { pfFormSchema } from '../pessoaFisica/pfForm';
import { enderecoFormSchema } from '../endereco/enderecoForm';
import { changeInput } from '../form/formReducer';

const INITIAL_STATE = {
	cpf: '',
	pf: pfFormSchema,
	endereco: enderecoFormSchema,
	isLoaded: false
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'CLIENTE_CHANGE': 
			return changeInput(state, action);
		case 'CLIENTE_UPDATE':
			return changeInput(state, action);
		case 'CLIENTE_GET':
			return { ...action.payload };
		case 'CLIENTE_VIEW':
			return action.payload;
		case 'CLIENTE_CLEAR':
			return INITIAL_STATE;
		default:
			return state;
	}
};