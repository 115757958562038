import React from 'react';
import { FormText, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import InputMask from 'react-input-mask';
import axiosAuth from '../../helpers/axiosAuth';
import FormGroupLoading from '../form/formGroupLoading';

class pfForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			searchingCPF: false,
			cpfOnly: props.pf ? false : true,
			disabled: false
		};

		this.changeCPF = this.changeCPF.bind(this);
		this.handleBlurCPF = this.handleBlurCPF.bind(this);
	}

	changeCPF(event) {
		this.props.change(event);
		const cpf = event.target.value.replace(/\.|-/g, '');
		
		if (cpf.length === 11) {
			this.setState({disabled: true, searchingCPF: true});
			axiosAuth.get(`pessoa-fisica/check/${cpf}`)
				.then(response => {
					if (this.props.cbCPF) {
						this.props.cbCPF(response.data);
					}
					if (response.data.status === false) {
						this.setState({disabled: false});
					}
					this.setState({cpfOnly: false, searchingCPF: false});
				}).catch(err => {
					alert('Ocorreu ao realizar a consulta. Por favor tente novamente!');
					this.setState({disabled: false, searchingCPF: false});
				});
		}
	}

	handleBlurCPF(event) {
		if(event.target.value.length > 0 && event.target.value.length !== 14) {
			alert('CPF incompleto!');	
			this.props.change({target:{name:'pf.cpf'}}, '');
			event.target.focus();
		}
	}

	render() {
		return this.props.pf ? (
			<fieldset>
				<legend>Dados Pessoa Física</legend>
				{
					this.state.cpfOnly && !this.props.pf.id_pessoa_fisica ? (
						<Row>
							<Col sm="4">
								<FormGroupLoading loading={this.state.searchingCPF}>
									<Label for="cpf">CPF</Label>
									<InputMask mask="999.999.999-99" maskChar="" name="pf.cpf" id="cpf" className="form-control" value={this.props.pf.cpf} onChange={this.changeCPF} disabled={this.props.disabled || this.state.disabled} onBlur={this.handleBlurCPF} />
								</FormGroupLoading>
							</Col>
						</Row>
					) : (
						<>
						<FormGroup>
							<Label for="nome">Nome</Label>
							<Input type="text" name="pf.nome" id="nome" value={this.props.pf.nome} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
						</FormGroup>
						<Row>
							<Col sm="8">
								<FormGroup>
									<Label for="nome_social">Nome Social</Label>
									<Input type="text" name="pf.nome_social" id="nome_social" value={this.props.pf.nome_social} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
									<FormText>Nome pelo qual pessoas trans, transexuais ou travestis, preferem serem chamadas.</FormText>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="sexo">Sexo</Label>
									<Input type="select" name="pf.sexo" id="sexo" value={this.props.pf.sexo} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled}>
										<option value="1">Masculino</option>
										<option value="0">Feminino</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label for="cpf">CPF</Label>
									<InputMask mask="999.999.999-99" maskChar="" name="pf.cpf" id="cpf" className="form-control" value={this.props.pf.cpf} onChange={this.changeCPF} disabled={this.props.disabled || this.state.disabled} onBlur={this.handleBlurCPF} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="rg">RG</Label>
									<Input type="text" name="pf.rg" id="rg" value={this.props.pf.rg} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="data_nascimento">Data de nascimento</Label>
									<Input type="date" name="pf.data_nascimento" id="data_nascimento" value={this.props.pf.data_nascimento} required onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="email">E-mail</Label>
									<Input type="email" name="pf.email" id="email" value={this.props.pf.email} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label htmlFor="telefone">Telefone</Label>
									<InputMask mask="(99)999999999" maskChar="" name="pf.telefone" id="telefone" className="form-control" value={this.props.pf.telefone} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label htmlFor="telefone2">Telefone 2</Label>
									<InputMask mask="(99)999999999" maskChar="" name="pf.telefone2" id="telefone2" className="form-control" value={this.props.pf.telefone2} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label for="pai">Nome do pai</Label>
									<Input type="text" name="pf.pai" id="pai" value={this.props.pf.pai} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
							<Col>	
								<FormGroup>
									<Label for="mae">Nome da mãe</Label>
									<Input type="text" name="pf.mae" id="mae" value={this.props.pf.mae} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label for="responsavel">Nome do responsável</Label>
							<Input type="text" name="pf.responsavel" id="responsavel" value={this.props.pf.responsavel} onChange={this.props.change} disabled={this.props.disabled || this.state.disabled} />
							<FormText>Campo a ser preenchido para pessoas civilmente incapazes ou interditadas.</FormText>
						</FormGroup>
						</>
					)
				}
			</fieldset>
		) : '';
	}
}

export default pfForm;

export const pfFormSchema = {
	'id_pessoa_fisica': '',
	'nome': '',
	'nome_social': '',
	'data_nascimento': '',
	'cpf': '',
	'rg': '',
	'sexo': 1,
	'email': '',
	'telefone': '',
	'telefone2': '',
	'pai': '',
	'mae': '',
	'responsavel': ''
};