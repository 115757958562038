import React from 'react';
import { Icon, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';

class TableSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };

    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  getColumnSearchProps(dataIndex) {
      return {
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={node => { this.searchInput = node; }}
              placeholder={`Buscar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 110, marginRight: 8 }}
            >
              Pesquisar
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 70 }}
            >
              Limpar
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
    };
  }

  handleSearch(selectedKeys, confirm) {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset(clearFilters) {
    clearFilters();
    this.setState({ searchText: '' });
  }
}

export default TableSearch;
